.modal {
  @apply hidden items-center flex-col justify-center overflow-hidden fixed inset-0 z-40;
}

.modal.active {
  @apply flex;
}

.modal-background {
  @apply absolute inset-0 bg-gray-900 bg-opacity-80;
}

.modal-card {
  max-height: calc(100vh - 160px);
  @apply w-full flex flex-col overflow-hidden relative
    lg:mx-auto lg:w-2/5;
}

.modal-card-body {
  @apply bg-white flex-grow flex-shrink overflow-auto p-6 space-y-2;
}

.modal-card-head, .modal-card-foot {
  @apply flex items-center flex-shrink-0 justify-start px-6 py-4 relative bg-gray-100 border-gray-200;
}

.modal-card-head {
  @apply border-b rounded-t;
}

.modal-card-foot {
  @apply border-t rounded-b;
}

.modal-card-foot .button:not(:last-child) {
  @apply mr-2;
}
