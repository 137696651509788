progress {
  @apply h-3 rounded-full overflow-hidden;
}

progress::-webkit-progress-bar {
  @apply bg-gray-200;
}

progress::-webkit-progress-value {
  @apply bg-blue-500;
}

progress::-moz-progress-bar {
  @apply bg-blue-500;
}

progress::-ms-fill {
  @apply bg-blue-500 border-0;
}
