.aside {
  @apply w-60 -left-60 fixed top-0 z-40 h-screen bg-gray-800 transition-all lg:left-0;
}

.aside-tools {
  @apply flex flex-row w-full bg-gray-900 text-white flex-1 px-3 h-14 items-center;
}

.aside-mobile-expanded .aside {
  @apply left-0;
}

.aside-mobile-expanded .navbar {
  @apply ml-60;
}

.aside-mobile-expanded #app {
  @apply ml-60;
}

.aside-mobile-expanded, .aside-mobile-expanded body {
  @apply overflow-hidden lg:overflow-visible;
}

.menu-label {
  @apply p-3 text-xs uppercase text-gray-400;
}

.menu-list li a {
  @apply py-2 flex text-gray-300;
}

.menu-list li > a {
  @apply hover:bg-gray-700;
}

.menu-list li a .menu-item-label {
  @apply flex-grow;
}

.menu-list li a .icon {
  @apply w-12 flex-none;
}

.menu-list li.active > a {
  @apply bg-gray-700;
}

.menu-list li ul {
  @apply hidden;
}

.menu-list li ul a {
  @apply p-3 text-sm;
}

.menu-list li.active ul {
  @apply block bg-gray-600;
}

